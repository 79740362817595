/**
 * Metadata Types
 */

export interface ISettings {
  decimalsStore: { [key: string]: number }
}

/**
 * Metadata Actions
 */

export enum Enum {
  CLEAR = "metadata/CLEAR",

  DECIMALS_STORE_SET = "metadata/DECIMALS_STORE_SET",
}

export type AClear = {
  type: typeof Enum.CLEAR
}

export type ADecimalsStoreSet = {
  type: typeof Enum.DECIMALS_STORE_SET
  decimalsStore: { [key: string]: number }
}

export type Actions = AClear | ADecimalsStoreSet
