import "@/styles/style.scss"
import 'aos/dist/aos.css'
import React, { useEffect } from "react"
import { createRoot, hydrateRoot } from 'react-dom/client'
import type { GatsbyBrowser } from "gatsby"
import ReduxProvider from "./src/redux/provider"
import ThemeProvider from "./src/theme"
import MainLayout from "./src/components/common/__Layout"
import WikiLayout from "./src/components/pages/Wiki/__Layout"
import WalletLayout from "./src/components/pages/Wallet/__Layout"
import AssetsLayout from "./src/components/pages/Assets/__Layout"
import TransactionsLayout from "./src/components/pages/Transactions/__Layout"
import StakeLayout from "./src/components/pages/Stake/__Layout"
import ExplorerLayout from "./src/components/pages/Explorer/__Layout"

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  return (
    <ReduxProvider>
      <ThemeProvider>
        {element}
      </ThemeProvider>
    </ReduxProvider>
  )
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  const path = props?.location?.pathname
  if (path.startsWith('/wiki')) {
    return <MainLayout {...props} children={<WikiLayout {...props} children={element} />} />
  }
  if (path.startsWith('/wallet')) {
    return <MainLayout {...props} children={<WalletLayout {...props} children={element} />} />
  }
  if (path.startsWith('/assets')) {
    return <MainLayout {...props} children={<AssetsLayout {...props} children={element} />} />
  }
  if (path.startsWith('/transactions')) {
    return <MainLayout {...props} children={<TransactionsLayout {...props} children={element} />} />
  }
  if (path.startsWith('/stake')) {
    return <MainLayout {...props} children={<StakeLayout {...props} children={element} />} />
  }
  if (path.startsWith('/explorer')) {
    return <MainLayout {...props} children={<ExplorerLayout {...props} children={element} />} />
  }
  return <MainLayout {...props} children={element} />
}

export function replaceHydrateFunction() {
  return (element: any, container: any, callback: any) => {
    createRoot(container).render(element)
  }
}
