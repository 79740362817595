import React, { useEffect, useState } from "react"
import { Checkbox, ConfigProvider, InputNumber, Switch, theme, ThemeConfig } from "antd"
import { StyleProvider, px2remTransformer } from "@ant-design/cssinjs"
import { useAppSelector } from "@/redux/provider"
import { merge } from "lodash"

export const colors = {
  /* colors */
  white: "#ffffff",
  black: "#040713",
  blue: "#1940ed",
  blueLight: "#436bfa",
  // blueDark: "#1a39c5",
  // yellow: "#ff0",
  orange: "#faad15",
  red: "#dc4446",
  // pink: "#fd3995",
  // purple: "#652eff",
  green: "#3fcb9b",

  /* light grays */
  gray1: "#f2f4f8",
  gray2: "#e8e8f0",
  gray3: "#dddfe2",
  // gray4: "#9aa0b6",
  // gray5: "#7b8199",
  // gray6: "#5a5b6e",

  /* dark grays */
  // darkGray1: "#b6b6c5",
  darkGray2: "#6e758d",
  // darkGray3: "#55556d",
  darkGray4: "#282d41",
  darkGray5: "#1e2232",
  // darkGray6: "#0e0e18",
}

export const px2rem = px2remTransformer({
  rootValue: 16,
  precision: 2,
})

export const restTheme: Partial<ThemeConfig> = {
  token: {
    fontFamily: '"Satoshi", sans-serif',
    fontSize: 16,
    colorPrimary: colors.blue,
    colorInfo: colors.blue,
    colorSuccess: colors.green,
    colorWarning: colors.orange,
    colorError: colors.red,
  },
  components: {
    Button: {
      contentFontSizeSM: 14,
      contentFontSize: 14,
      contentFontSizeLG: 16,
    },
    Input: {
      inputFontSizeSM: 14,
      inputFontSize: 16,
      inputFontSizeLG: 16,
    },
    InputNumber: {
      inputFontSizeSM: 14,
      inputFontSize: 16,
      inputFontSizeLG: 16,
    },
    Select: {
      fontSizeSM: 14,
      fontSize: 16,
      fontSizeLG: 16,
    },
    Tabs: {
      horizontalItemGutter: 25,
    },
    Modal: {
      borderRadiusLG: 20,
    },
  },
}

export const defaultTheme: Partial<ThemeConfig> = {
  algorithm: theme.defaultAlgorithm,
  token: merge({}, restTheme.token, {
    colorFillAlter: colors.white,
  }),
  components: merge({}, restTheme.components, {
    Button: {
      colorFill: colors.gray2,
      colorFillSecondary: colors.gray2,
      colorFillTertiary: colors.gray1,
      borderColorDisabled: colors.gray1,
      colorBgContainerDisabled: colors.gray1,
    },
    Modal: {
      colorBgMask: "rgba(242,244,248,.8)",
      boxShadow: "0 6px 30px 0 rgba(0,0,0,.03), 0 3px 6px -4px rgba(0,0,0,.03), 0 9px 28px 8px rgba(0,0,0,.03)",
    },
  }),
}

export const darkTheme: Partial<ThemeConfig> = {
  algorithm: theme.darkAlgorithm,
  token: merge({}, restTheme.token, {
    colorPrimary: colors.blueLight,
    colorInfo: colors.blueLight,
    colorFillAlter: colors.black,
  }),
  components: merge({}, restTheme.components, {
    Button: {
      colorFill: colors.darkGray4,
      colorFillSecondary: colors.darkGray4,
      colorFillTertiary: colors.darkGray5,
      defaultBg: colors.black,
      defaultHoverBg: colors.black,
      defaultBorderColor: colors.darkGray4,
      borderColorDisabled: colors.darkGray5,
      colorBgContainerDisabled: colors.darkGray5,
    },
    Tabs: {
      itemActiveColor: colors.white,
      itemSelectedColor: colors.white,
      inkBarColor: colors.white,
      itemHoverColor: colors.gray3,
    },
    Tag: {
      defaultBg: "transparent",
      colorBorder: colors.darkGray4,
    },
    Input: {
      colorBgContainer: "transparent",
      colorBorder: colors.darkGray4,
      colorBgContainerDisabled: colors.darkGray5,
      colorTextDisabled: colors.darkGray2,
    },
    InputNumber: {
      colorBgContainer: "transparent",
      colorBorder: colors.darkGray4,
      colorBgContainerDisabled: colors.darkGray5,
      colorTextDisabled: colors.darkGray2,
    },
    Select: {
      colorBgContainer: "transparent",
      colorBorder: colors.darkGray4,
      colorBgContainerDisabled: colors.darkGray5,
      colorTextDisabled: colors.darkGray2,
      selectorBg: "transparent",
      colorBgElevated: colors.darkGray4,
    },
    Dropdown: {
      colorBgElevated: colors.darkGray4,
    },
    Popover: {
      colorBgElevated: colors.darkGray4,
    },
    Radio: {
      colorBgContainer: "transparent",
      colorBorder: colors.darkGray4,
      colorBgContainerDisabled: colors.darkGray5,
      colorTextDisabled: colors.darkGray2,
    },
    Checkbox: {
      colorBgContainer: "transparent",
      colorBorder: colors.darkGray4,
      colorBgContainerDisabled: colors.darkGray5,
      colorTextDisabled: colors.darkGray2,
    },
    Modal: {
      contentBg: colors.black,
      colorBgMask: "rgba(4,7,19,.95)",
      boxShadow: "0 0 60px 0 rgba(29, 21, 48, .9)",
    },
  }),
}

const ThemeProvider = ({ children, cache }: { children: React.ReactNode; cache?: any }) => {
  const theme = useAppSelector((state) => state.app.theme)
  const scale = useAppSelector((state) => state.app.scale)
  const defaultThemeConfig = {
    theme: "default",
    palette: defaultTheme,
  }
  const darkThemeConfig = {
    theme: "dark",
    palette: darkTheme,
  }

  const config =
    theme === "system"
      ? global?.window?.matchMedia("(prefers-color-scheme: dark)").matches
        ? darkThemeConfig
        : defaultThemeConfig
      : theme === "default"
        ? defaultThemeConfig
        : darkThemeConfig

  const html = global?.document?.querySelector("html")
  if (html) {
    html.setAttribute("data-disable-transitions", "true")
    html.setAttribute("data-theme", config.theme)
    html.setAttribute("data-scale", scale)
    setTimeout(() => {
      html.removeAttribute("data-disable-transitions")
    }, 500)
  }

  return (
    <ConfigProvider theme={config.palette}>
      <StyleProvider transformers={[px2rem]} cache={cache}>
        {children}
      </StyleProvider>
    </ConfigProvider>
  )
}

export default ThemeProvider
