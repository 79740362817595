import React, { useState, useEffect } from "react"
import { AccountTypes } from "@/redux/account"
import { Tooltip } from "antd"

const InformerBreakdown = ({
  items,
}: {
  items: {
    title?: React.ReactElement | string
    children?: React.ReactElement | string
    help?: string
    hideDots?: boolean
  }[]
}) => {
  return (
    <div>
      {items.map((item, index) => (
        <div key={index}>
          <div className="xray-breakdown-item">
            {item.title && (
              <div className="xray-breakdown-title">
                {item.title}
                {item.help && (
                  <Tooltip title={item.help}>
                    <i className="xi xi-info ms-2 text-muted" />
                  </Tooltip>
                )}
              </div>
            )}
            {!item.hideDots && <div className="xray-breakdown-dots" />}
            <div className="xray-breakdown-quantity">{item.children}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default InformerBreakdown
